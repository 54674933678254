<template>
    <div>
      <el-row :gutter="20" class="search-box">

        <el-col :span="4">
        <el-select
          placeholder="请选择站长代理"
          v-model="query.agentId"
          clearable
        >
          <el-option
            v-for="(item, index) in zzAgentList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="状态" v-model="query.status" clearable>
          <el-option
            v-for="(item, index) in this.const.AGENT_USER_STATUS"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </el-col>
      </el-row>
      <div>
        <el-button
          class="but"
          type="primary"
          @click="showAddUser"
          v-auth="this.per.AGENT_EMPLOYEE_ADD"
          >添加账户</el-button
        >
      </div>
      <el-table
        border
        :data="dataSource"
        class="page-top-space"
        row-class-name="table-row"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="站长名称" prop="agentName"></el-table-column>
        <el-table-column label="用户名" prop="userName"></el-table-column>
        <el-table-column label="套餐到期时间" prop="productEndTime"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template #default="scope">
            <eb-badge
              :list="this.const.AGENT_USER_STATUS"
              :target="scope.row.status"
            ></eb-badge>
          </template>
        </el-table-column>

        <el-table-column label="入职时间" prop="createTime"></el-table-column>

        <el-table-column label="离职时间" prop="dismissTime"></el-table-column>
  
         
      </el-table>
      <el-pagination
        layout=" total,prev, pager, next"
        background
        :total="total"
        :page-size="pages.pageSize"
        :current-page="pages.pageIndex"
        @current-change="getList"
      ></el-pagination>
  
      <EditModal
        :agentList="agentList"
        :modalData="modalData"
        :modalType="modalType"
        :ref="MODAL_KEY.EDIT_MODAL"
        @submit="submitUser"
      ></EditModal>
    </div>
  </template>
    
    <script>
  import { fetchAgentUserPage } from "@/api/zzAgent";
  import { mapState } from "vuex";

  export default {
    computed: mapState({
    zzAgentList(state) {
      return state.zzAgent.list || []; 
    },
  }),
    data() {
      return {
        dataSource: [],
        query: {
          status: this.const.AGENT_USER_STATUS_CODE.USE,
        },
        pages: {
          pageIndex: 1,
          pageSize: 10,
        },
        modalData: {},
        modalType: "add",
        MODAL_KEY: {
          EDIT_MODAL: "EDIT_MODAL",
        },
        total: 0,
      };
    },
    methods: {
     
      showAdd() {
        this.modalData = {};
        this.modalType = "add";
        this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
      },
      showEdit(data) {
        this.modalData = this.deepClone(data);
        this.modalType = "edit";
        this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
      },
  
      submit(data) {
        let request = updateAgent;
        let msg = "编辑用户成功";
        if (this.validatenull(data.id)) {
          request = addAgent;
          msg = "添加用户成功";
          data.roleType = this.const.ROLE_TYPE_CODE.AGENT_MANAGE;
          data.stationId = 0;
        }
  
        request(data).then((res) => {
          this.$message.success(msg);
          this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
          this.getList();
        });
      },
  
      getList(current) {
        if (!this.validatenull(current)) {
          this.pages.pageIndex = current;
        }
        fetchAgentUserPage({ ...this.query, ...this.pages }).then((res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
        });
      },
    },
  
    mounted() {
      this.getList();
      this.$store.dispatch("fetchZZAgentListAll");
    },
  };
  </script>
    